import { render, staticRenderFns } from "./LinkedStickers.vue?vue&type=template&id=1c4929a7&scoped=true&"
import script from "./LinkedStickers.vue?vue&type=script&lang=js&"
export * from "./LinkedStickers.vue?vue&type=script&lang=js&"
import style0 from "./LinkedStickers.vue?vue&type=style&index=0&id=1c4929a7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c4929a7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VAvatar,VBtn,VIcon,VSlideGroup,VSlideItem})
