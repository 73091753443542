<template>
	<v-card min-height="457" height="100%" class="card-border">
		<v-card-text v-if="item" class="pb-4 voice-slot">
			<v-tooltip top color="tooltipBg">
				<template v-slot:activator="{ on, attrs }">
					<div v-if="voiceStickers && voiceStickers.length < 1" class="exclamation" v-bind="attrs" v-on="on">
						<img
							width="20"
							class="pointer-events-none"
							src="@/assets/icons/red-exclamation.png"
							@click.stop />
					</div>
				</template>
				<strong class="offWhite--text">
					This voice is not yet assigned to any TTS stickers.
					<br />
					Tap the Assign Voice button to assign it to a TTS Sticker.
				</strong>
			</v-tooltip>

			<div class="d-flex align-center justify-center mb-5 mt-2">
				<img src="@/assets/icons/AIPersonal.png" class="mr-1" />
				<h4 class="fs--18 fw--300 text-center">{{ item.name }}</h4>
			</div>

			<p class="fs--15 fw--500 text-center mb-1">TTS Voice Preview</p>

			<div class="voice pt-3">
				<MusicPlayer :audio="audioUrl" :id="'voice-preview-' + item.id" />
			</div>

			<v-row no-gutters>
				<v-col cols="12" class="mt-1 d-flex align-center justify-space-between">
					<span class="fs--14 text-md fw--500">Tonal Variance:</span>
					<v-tooltip top color="tooltipBg">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<v-img
									width="40"
									class="pointer-events-none"
									src="@/assets/icons/info.png"
									@click.stop></v-img>
							</div>
						</template>
						<strong class="offWhite--text">
							Decreasing the Tonal Variance can help increase variability
							<br />
							and make your voice sound more lifelike between uses.
							<br />
							Increasing the Tonal Variance will increase the similarity
							<br />
							between each use, however, it could also make your voice
							<br />
							sound more monotone.
						</strong>
					</v-tooltip>
				</v-col>
				<v-col cols="12">
					<v-slider
						v-model="item.stability"
						@start="isInDataRangeChange = true"
						@end="
							isInDataRangeChange = false;
							actions.saveChanges();
						"
						color="pink"
						:min="30"
						:max="100"
						thumb-color="pinkItem"
						thumb-label
						track-fill-color="pinkItem"
						hide-details>
						<template #thumb-label>
							<span class="px-2">{{ item.stability }}%</span>
						</template>
					</v-slider>
				</v-col>
			</v-row>

			<!-- Hide accent enhancement due to EL model change -->
			<!--
			<v-row no-gutters>
				<v-col cols="12" class="d-flex justify-space-between align-center">
					<span class="fs--14 text-md fw--500">Multi-Language &amp; Accent Enhancement</span>
					<div class="d-flex flex-end pl-1">
						<v-switch v-model="item.enableAccentEnhancement" hide-details small class="ma-0" color="pink">
						</v-switch>
						<v-tooltip top color="tooltipBg">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									<v-img
										width="40"
										class="pointer-events-none"
										src="@/assets/icons/info.png"
										@click.stop></v-img>
								</div>
							</template>
							<strong class="offWhite--text"
								>Enable this option to enhance the TTS voice <br />
								performance of non-English speaking Channels. This can also help <br />
								improve the performance of TTS Voices with accents.</strong
							>
						</v-tooltip>
					</div>
				</v-col>
			</v-row>
			-->

			<v-row no-gutters class="mb-3">
				<v-col cols="12" class="mt-1 d-flex align-center justify-space-between">
					<span class="fs--14 text-md fw--500">Style Exaggeration:</span>
					<v-tooltip top color="tooltipBg">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<v-img
									width="40"
									class="pointer-events-none"
									src="@/assets/icons/info.png"
									@click.stop></v-img>
							</div>
						</template>
						<strong class="offWhite--text">
							High values are recommended if the style of the speech
							<br />
							should be exaggerated compared to the uploaded audio.
						</strong>
					</v-tooltip>
				</v-col>
				<v-col cols="12">
					<v-slider
						v-model="item.style"
						@start="isInDataRangeChange = true"
						@end="
							isInDataRangeChange = false;
							actions.saveChanges();
						"
						color="pink"
						:min="0"
						:max="50"
						:step="10"
						thumb-color="pinkItem"
						thumb-label
						track-fill-color="pinkItem"
						hide-details>
						<template #thumb-label>
							<span class="px-2">{{ item.style }}%</span>
						</template>
					</v-slider>
				</v-col>
			</v-row>

			<v-divider class="purpleDivider"></v-divider>

			<div class="text-center pa-4">
				<span class="fs--15 fw--500">TTS Voice Sticker Assignment</span>
			</div>

			<LinkedStickers :stickers="voiceStickers" @addSticker="actions.addSticker" />

			<div class="text-center pb-4">
				<span v-if="voiceStickers && voiceStickers.length > 0" class="fs--13 fw--100 white--text">
					This voice has been assigned to {{ voiceStickers.length }} TTS
					{{ voiceStickers.length > 1 ? 'Stickers' : 'Sticker' }}
				</span>
				<span v-if="!voiceStickers || voiceStickers.length < 1" class="fs--13 fw--100 white--text">
					To use this voice, you must first assign it to a
					<span class="pink--text fw--600 text-underline cursor-pointer" @click="actions.addSticker">
						TTS Sticker
					</span>
					. It will now appear in your list of A.I. configurable Voices as
					<img src="@/assets/icons/AIPersonal.png" class="mr-1" />
					<span style="font-weight: bold">[A.I. Personal] {{ item.name }}</span>
					.
				</span>
			</div>

			<v-divider class="purpleDivider"></v-divider>

			<div class="text-center pa-4">
				<span class="fs--15 fw--500">TTS Voice Sharing</span>
				<p class="fs--12 fw--100 mt-2">Share your TTS Voice with your friends so they can use it.</p>

				<v-btn color="pink" depressed @click="actions.shareVoice()">
					<v-icon class="mr-2">{{ icons.addUser }}</v-icon>
					<span class="fs--13 fw--400">Share Voice</span>
				</v-btn>
			</div>

			<div class="mt-4 mb-5">
				<VoiceShares :items="item.shares" />
			</div>

			<div class="text-center">
				<v-btn @click="actions.deleteVoice(item.id)" color="secondary" depressed>
					<v-icon class="mr-2">{{ icons.delete }}</v-icon>
					<span class="fs--13 fw--400">Delete Voice</span>
				</v-btn>
			</div>
		</v-card-text>

		<GenericPopup
			v-if="shareModal"
			:modal="shareModal"
			:persistent="true"
			:closeButton="false"
			@close="shareModal = false">
			<template #primaryHeader>Voice Share</template>

			<template #secondaryHeader>Share your voice with broadcasters.</template>

			<template>
				<v-form @submit.prevent="actions.addUser()">
					<v-text-field
						v-model="newUser"
						placeholder="Enter Broadcaster Name"
						solo
						flat
						dense
						hide-details
						background-color="purple"></v-text-field>

					<div class="text-right mt-3">
						<v-btn type="submit" :disabled="!newUser" :loading="isLoading" color="pink" depressed>
							Share Voice
						</v-btn>
					</div>
				</v-form>
			</template>
		</GenericPopup>
	</v-card>
</template>

<script>
import { onBeforeUnmount, computed, ref, toRef, watch } from '@vue/composition-api';
import store from '@/store';
import axios from '@axios';
import router from '@/router';
import LinkedStickers from './LinkedStickers.vue';
import VoiceShares from './VoiceShares.vue';
import MusicPlayer from './MusicPlayer.vue';
import GenericPopup from '@/components/GenericPopup.vue';
import { FormChangeWatcher } from '../../../utils/form';
import { maxVoiceShares, StickerType } from '../../../utils/constants';
import { getVoicePreviewUrl } from '../../../utils/tts-utils';
import { showGlobalError } from '../../../utils/error';
import { saveEvent } from '@/utils/gtag';
import { mdiAccountMultiplePlus, mdiDelete, mdiOpenInNew } from '@mdi/js';

export default {
	props: {
		item: {
			type: Object,
		},
		// Used to control the form change watcher.
		loading: {
			type: Boolean,
		},
	},
	components: {
		LinkedStickers,
		VoiceShares,
		MusicPlayer,
		GenericPopup,
	},
	emits: ['onDelete'],
	setup(props, ctx) {
		const shareModal = ref(false);
		const newUser = ref('');
		const isLoading = ref(false);

		const voiceStickers = computed(() => {
			if (props.item && props.item.assignedStickers) {
				return props.item.assignedStickers;
			}
			return [];
		});

		const cb = ref(new Date().getTime());
		const audioUrl = computed(() => {
			return getVoicePreviewUrl(props.item.id) + '&cb=' + cb.value;
		});

		const isInDataRangeChange = ref(false);

		const actions = {
			addSticker() {
				// Add New Sticker
				router.push('/stickers-tts?tab=custom-ai-tts');
			},
			shareVoice() {
				shareModal.value = true;
			},

			addUser() {
				if (props.item.shares.length == maxVoiceShares) {
					store.commit(
						'app/SET_GLOBAL_ERROR_MESSAGE',
						`Sorry, you can only share to ${maxVoiceShares} people.`,
					);
					return;
				}

				axios
					.get(`/tts/vlab/broadcaster/${newUser.value}`)
					.then(res => {
						if (props.item.shares.findIndex(item => item.id === res.data.id) === -1) {
							props.item.shares.push(res.data);
							newUser.value = '';
							shareModal.value = false;

							saveEvent({
								event: 'voice_model_shared',
								params: {
									event_category: 'engagement',
									event_label: 'User Shares a voice model',
								},
							});
						} else {
							showGlobalError('Broadcaster is already in the list.');
						}
					})
					.catch(() => {
						// noop
					})
					.finally(() => {
						newUser.value = '';
					});
			},

			saveChanges() {
				// Do not save while a slider is changing.
				if (isInDataRangeChange.value) {
					return;
				}

				// Save the voice and reload the music player.
				axios.put('/tts/vlab/voice', props.item).then(() => {
					cb.value = new Date().getTime();
				});
			},

			deleteVoice(id) {
				axios.delete(`/tts/vlab/voice/${id}`).then(() => {
					ctx.emit('onDelete', id);
				});
			},
		};

		// Watch for changes in the form.
		const fw = new FormChangeWatcher();
		fw.startWatching(props.item, actions.saveChanges);

		onBeforeUnmount(() => {
			fw.stopWatching();
		});

		// This watcher is for when data is reloaded (e.g. after delete.)
		// We need to restart the form watcher after data is reloaded.
		const loadingRef = toRef(props, 'loading');
		watch(loadingRef, newVal => {
			if (newVal) {
				fw.stopWatching();
			} else {
				fw.startWatching(props.item, actions.saveChanges);
			}
		});

		return {
			voiceStickers,
			shareModal,
			newUser,
			isLoading,
			audioUrl,
			isInDataRangeChange,
			actions,
			icons: {
				addUser: mdiAccountMultiplePlus,
				delete: mdiDelete,
				open: mdiOpenInNew,
			},
		};
	},
};
</script>

<style scoped>
.voice-slot {
	position: relative;
}
.exclamation {
	position: absolute;
	top: 8px;
	right: 8px;
}
</style>
