<template>
	<div>
		<div
			v-if="item.tts && item.tts.bounds"
			class="base-image"
			:style="{
				backgroundImage: `url(${actions.getFullStickerImageUrl(item)}`,
				minHeight: actions.calcHeight(),
				minWidth: actions.calcWidth(),
				maxHeight: actions.calcHeight(),
				maxWidth: actions.calcWidth(),
				backgroundPosition: 'center center',
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
			}">
			<!-- Set Eye Image -->
			<div
				v-if="item.tts.isEyeEnabled && item.tts.eyeImage && item.tts.bounds"
				:style="{
					position: 'absolute',
					width: '100%',
					height: '100%',
				}">
				<div
					:style="{
						position: 'absolute',
						backgroundImage: `url(${actions.getImage('eyes', item.tts.eyeImage)})`,
						backgroundSize: 'contain',
						backgroundPosition: 'center center',
						zIndex: 4,
						transform: item.tts.isEyeFlipped
							? `rotate(${item.tts.position.eyeAngle}deg) scaleX(-1)`
							: `rotate(${item.tts.position.eyeAngle}deg) scaleX(1)`,
						left: `${(item.tts.bounds.eyeX / 250) * 100}%`,
						top: `${(item.tts.bounds.eyeY / 300) * 100}%`,
						width: `${(item.tts.bounds.eyeW / 100) * size}px`,
						height: `${(item.tts.bounds.eyeH / 100) * size}px`,
					}"></div>
			</div>

			<!-- Set Animated Mouth Image -->

			<div
				v-show="item.tts.isMouthEnabled && item.tts.mouthImage && item.tts.bounds && isAnimatedMouthRequired"
				:style="{
					position: 'absolute',
					width: '100%',
					height: '100%',
				}">
				<div
					:style="{
						position: 'absolute',
						backgroundImage: `url(${actions.getImage('mouth', item.tts.mouthImage)})`,
						backgroundSize: 'contain',
						backgroundPosition: 'center center',
						zIndex: 4,
						transform: item.tts.isMouthFlipped
							? `rotate(${item.tts.position.mouthAngle}deg) scaleX(-1)`
							: `rotate(${item.tts.position.mouthAngle}deg) scaleX(1)`,
						left: `${(item.tts.bounds.mouthX / 250) * 100}%`,
						top: `${(item.tts.bounds.mouthY / 300) * 100}%`,
						width: `${(item.tts.bounds.mouthW / 100) * size}px`,
						height: `${(item.tts.bounds.mouthH / 100) * size}px`,
					}"></div>
			</div>

			<!-- Set Closed Mouth Image -->

			<div
				v-show="item.tts.isMouthEnabled && item.tts.isClosedMouthEnabled && !isAnimatedMouthRequired"
				:style="{
					position: 'absolute',
					width: '100%',
					height: '100%',
				}">
				<div
					:style="{
						position: 'absolute',
						backgroundImage: `url(${actions.getClosedMouth(item)})`,
						backgroundSize: 'contain',
						backgroundPosition: 'center center',
						zIndex: 4,
						transform: item.tts.isMouthFlipped
							? `rotate(${item.tts.position.mouthAngle}deg) scaleX(-1)`
							: `rotate(${item.tts.position.mouthAngle}deg) scaleX(1)`,
						left: `${(item.tts.bounds.mouthX / 250) * 100}%`,
						top: `${(item.tts.bounds.mouthY / 300) * 100}%`,
						width: `${(item.tts.bounds.mouthW / 100) * size}px`,
						height: `${(item.tts.bounds.mouthH / 100) * size}px`,
					}"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { getHostedAssetPath, getGlobalStickerAssetPath } from '@/utils/config.js';
export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
		rounded: {
			type: Boolean,
			required: false,
		},
		isAnimatedMouthRequired: {
			type: Boolean,
			default: true,
		},
		size: {
			type: String || Number,
			default: '25', // Size will be passed as percentage to display the Sticker Assets
		},
		// Global TTS stickers have different image asset paths.
		isGlobal: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const isPresetFile = file => {
			return file.indexOf('/') < 0;
		};

		const actions = {
			calcHeight() {
				return (300 / 100) * (props.size * 1) + 'px';
			},
			calcWidth() {
				return (250 / 100) * (props.size * 1) + 'px';
			},
			getFullStickerImageUrl(item) {
				if (!props.isGlobal) {
					return getHostedAssetPath() + '/' + item.tts.baseImage;
				} else {
					return getGlobalStickerAssetPath() + '/' + item.tts.baseImage;
				}
			},
			getImage(type, image) {
				if (!props.isGlobal) {
					if (type == 'eyes') {
						return getHostedAssetPath() + '/' + image;
					} else if (type == 'mouth') {
						return getHostedAssetPath() + '/' + image;
					}
				} else {
					if (type == 'eyes') {
						if (isPresetFile(image)) {
							return getGlobalStickerAssetPath() + '/tts/eyes/' + image;
						} else {
							return getGlobalStickerAssetPath() + '/' + image;
						}
					} else if (type == 'mouth') {
						if (isPresetFile(image)) {
							return getGlobalStickerAssetPath() + '/tts/mouth/animated/' + image;
						} else {
							return getGlobalStickerAssetPath() + '/' + image;
						}
					}
				}

				return null;
			},

			getClosedMouth(item) {
				if (!props.isGlobal) {
					return getHostedAssetPath() + '/' + item.tts.closedMouthImage;
				} else {
					if (item.tts.closedMouthImage) {
						return getGlobalStickerAssetPath() + '/' + item.tts.closedMouthImage;
					} else if (isPresetFile(item.tts.mouthImage)) {
						return getGlobalStickerAssetPath() + '/tts/mouth/closed/' + item.tts.mouthImage;
					}
				}
			},
		};

		return {
			actions,
		};
	},
};
</script>

<style lang="scss" scoped>
.base-image {
	position: relative;
	margin: auto;
}
</style>
